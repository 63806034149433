import { useState, useEffect } from "react";

const sortByDate = (items) => {
  return items.slice().sort((a, b) => {
    const dateA = new Date(a.node.updatedAt);
    const dateB = new Date(b.node.updatedAt);
    return dateB.getTime() - dateA.getTime();
  });
};

export const useContentTopicFiltering = ({ allPages = [], topic = "" }) => {
  const [resultCount, setResultCount] = useState(allPages.length);
  const [resultPages, setResultPages] = useState(allPages);

  useEffect(() => {
    const newPages = allPages.filter((item) => {
      return (
        item.node.contentTopics &&
        item.node.contentTopics.some((item) => {
          return topic === item.title;
        })
      );
    });

    const newPagesSorted = sortByDate(newPages);

    setResultCount(newPagesSorted.length);
    setResultPages(newPagesSorted);
  }, []);

  return { resultCount, resultPages };
};
