/** @jsx jsx */
import { jsx, Box, Button, Flex } from "theme-ui";
import { useContext, useState } from "react";
import ContentTopicCardList from "./ContentTopicCardList";
import { LocalizationContext } from "../context/LocalizationContext";

const ITEMS_PER_PAGE = 6;

const ContentTopicPager = ({
  resultPages,
  language,
  isInInitialState,
  title,
  noHeroOnPage,
  ...props
}) => {
  const { translate } = useContext(LocalizationContext);
  const [displayPage, setDisplayPage] = useState(0);

  const renderPager = (items) => {
    const handlePageSelection = (pageNumber) => {
      setDisplayPage(--pageNumber);
    };

    const totalPageCount = Math.ceil(items.length / ITEMS_PER_PAGE);

    const renderButton = (pageNumber) => {
      return (
        <Button
          as="li"
          key={`pager-button-${pageNumber}`}
          sx={{
            variant:
              pageNumber === displayPage
                ? "buttons.pagerSelected"
                : "buttons.pager",
            border: "none",
          }}
          onClick={() => handlePageSelection(pageNumber)}
        >
          {++pageNumber}
        </Button>
      );
    };

    let truncatedLeft = 0;
    let truncatedRight = 0;

    const renderDots = (index) => {
      const render = (i) => {
        return (
          <Button
            key={`pager-button-${i}`}
            disabled
            variant="buttons.pager"
            sx={{
              border: "none",
              ":hover": {
                color: "black",
              },
            }}
          >
            {"..."}
          </Button>
        );
      };

      if (displayPage < index) {
        truncatedRight++;
        if (truncatedRight === 1) {
          return render(index);
        }
      }

      if (displayPage > index) {
        truncatedLeft++;
        if (truncatedLeft === 1) {
          return render(index);
        }
      }
    };

    return Array.from({ length: totalPageCount }, (_, index) => {
      if (index === 0) {
        return renderButton(index);
      }

      if (index === totalPageCount - 1) {
        return renderButton(index);
      }

      // Always show last 4 pages when at the end of the list
      if (displayPage >= totalPageCount - 2 && index >= totalPageCount - 5) {
        return renderButton(index);
      }

      if (displayPage > 3 && index <= displayPage - 3) {
        return renderDots(index);
      }

      if (displayPage + 1 >= index) {
        return renderButton(index);
      }

      if (displayPage <= 4 && index <= 4) {
        return renderButton(index);
      }

      return renderDots(index);
    });
  };

  const renderNoResultsText = () => {
    return (
      <Box>
        <p sx={{ display: "inline", fontWeight: "bold" }}>
          {translate("contentHub.oops", null, language)}
        </p>{" "}
        {translate("contentHub.noResultsFound", null, language)}
      </Box>
    );
  };

  const pagingStartPage = displayPage * ITEMS_PER_PAGE;
  const pagingEndPage = displayPage
    ? (displayPage + 1) * ITEMS_PER_PAGE
    : ITEMS_PER_PAGE;
  const displayPages = resultPages.slice(pagingStartPage, pagingEndPage);

  return (
    <Box {...props}>
      {isInInitialState || displayPages.length > 0 ? (
        <ContentTopicCardList
          displayPages={displayPages}
          title={title}
          noHeroOnPage={noHeroOnPage}
        />
      ) : (
        renderNoResultsText()
      )}
      <Flex as="nav" sx={{ justifyContent: "center", my: 2 }}>
        <Box as="ul">{renderPager(resultPages)}</Box>
      </Flex>
    </Box>
  );
};

export default ContentTopicPager;
