/** @jsx jsx */
import { jsx, Grid } from "theme-ui";
import ContentTopicItem from "../components/ContentTopicItem";

const ContentTopicCardList = ({ displayPages }) => {
  if (!Array.isArray(displayPages)) return null;

  if (displayPages.length < 1) {
    return null;
  }

  return (
    <Grid
      aria-live="polite"
      sx={{
        gridTemplateColumns: ["1fr", "repeat(2, 1fr)", "repeat(3, 1fr)"],
      }}
    >
      {displayPages.map((item, index) => {
        const node = item.node;
        return <ContentTopicItem item={node} index={index} key={item.slug} />;
      })}
    </Grid>
  );
};

export default ContentTopicCardList;
