import { useContext } from "react";
import { jsx, Box, Image, Heading } from "theme-ui";
import Link from "./Link";
import { GatsbyImage } from "gatsby-plugin-image";
import defaultImage from "../../static/fennia-some-default.png";
import { getUrlFromSlug } from "../utils/utils";
import { useSitePages } from "../hooks/useSitePages";
import { LocalizationContext } from "../context/LocalizationContext";

const ContentTopicItem = ({ item, index }) => {
  const { title, headerImage, hero, navigationIcon, slug } = item;
  const { translate } = useContext(LocalizationContext);
  const nodes = useSitePages();

  const image = headerImage ? headerImage : hero ? hero.image : navigationIcon;

  const linkPath = getUrlFromSlug(slug, nodes);

  return (
    <Box
      sx={{
        my: 1,
      }}
    >
      <Link
        target={linkPath}
        aria-labelledby={`content-card-title-${slug}-${index}`}
        sx={{
          position: "relative",
          display: "block",
          transition: "all .15s ease",
          ":hover": {
            transform: "scale(1.05) translateY(-2.5%)",
          },
        }}
      >
        {image?.gatsbyImageData ? (
          <Box sx={{ m: 0 }}>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.description ? image.description : ""}
              sx={{ width: "100%", height: "auto" }}
            />
          </Box>
        ) : (
          <Box sx={{ position: "relative" }}>
            <Box sx={{ width: "100%", paddingBottom: "50%" }} />
            <Image
              src={defaultImage}
              sx={{
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "50% 50%",
              }}
              aria-hidden="true"
              alt=""
            />
          </Box>
        )}
      </Link>
      <Box>
        <Heading as="h3" sx={{ mt: 2, mb: 1, fontSize: 4 }}>
          <Link
            target={linkPath}
            id={`content-card-title-${slug}-${index}`}
            sx={{ color: "black", ":hover": { color: "text", opacity: 0.9 } }}
          >
            {title}
          </Link>
        </Heading>
        <Link
          target={linkPath}
          id={`content-card-read-more-${slug}-${index}`}
          aria-labelledby={`content-card-read-more-${slug}-${index} content-card-title-${slug}-${index}`}
          showArrow
        >
          {translate("contentTopic.readMore")}
        </Link>
      </Box>
    </Box>
  );
};

export default ContentTopicItem;
