/** @jsx jsx */
import { jsx, Container, Themed, Spinner, Box } from "theme-ui";
import { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import BreadCrumbs from "../components/BreadCrumbs";
import ContentTopicPager from "../components/ContentTopicPager";
import { useSiteMetadata } from "../hooks/use-site-metadata";
import { useContentTopicFiltering } from "../hooks/useContentTopicFiltering";
import { LocalizationContext } from "../context/LocalizationContext";

const ContentTopicTemplate = ({ data, pageContext }) => {
  const { translate } = useContext(LocalizationContext);

  const { defaultDescription } = useSiteMetadata();

  const { language, paths, slug, topic, contentType, parentPage } =
    pageContext;

  const combinedPages = [
    ...data.allContentfulPage.edges,
    ...data.allContentfulProductPage.edges,
    ...data.allContentfulThemePage.edges,
  ];
  const allPages = combinedPages;

  const title = pageContext.topic;
  const metadataTitle = title;

  const currentPageSlug = paths[language]
  const parentNavigationTitle = parentPage.navigationTitle;
  const parentSlug = parentPage.slug;

  const { resultCount, resultPages } = useContentTopicFiltering({
    allPages,
    topic,
  });

  return (
    <Layout pageContext={pageContext} pageType={"Private Customer"}>
      <SEO
        lang={pageContext.language}
        title={metadataTitle}
        description={defaultDescription}
        paths={pageContext.paths}
      />
      <Container>
        <BreadCrumbs
          slug={currentPageSlug}
          navigationTitle={title}
          parentPage={{
            slug: parentSlug,
            navigationTitle: parentNavigationTitle,
          }}
          language={language}
        />
      </Container>
      <Container id="content" sx={{ py: [3, null, 5] }}>
        <Themed.h1
          sx={{
            my: 0,
          }}
        >
          {title}
        </Themed.h1>
        {resultCount === 0 ? (
          <Themed.p>{translate("contentTopic.noResultsFound")}</Themed.p>
        ) : resultCount && resultCount < allPages.length ? (
          <Box>
            <Themed.p
              sx={{
                my: 1,
              }}
            >
              {translate(
                resultCount > 1
                  ? "contentTopic.multipleResultsFound"
                  : "contentTopic.singleResultFound",
                { resultCount },
                language
              )}
            </Themed.p>
            <ContentTopicPager
              resultPages={resultPages}
              sx={{ my: "2em" }}
              language={language}
            />
          </Box>
        ) : (
          <Spinner
            title={translate("loading")}
            size="64"
            role="status"
            sx={{
              position: "relative",
              marginTop: "15em",
              width: "100%",
              zIndex: 99,
            }}
          />
        )}
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($node_locale: String!) {
    allContentfulPage: allContentfulPage(
      filter: { node_locale: { eq: $node_locale }, hidden: { ne: true } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      edges {
        node {
          title
          contentful_id
          slug
          updatedAt(locale: "fi-FI")
          node_locale
          contentTopics {
            title
            contentType {
              title
            }
          }
          internal {
            type
          }
          headerImage {
            gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
            description
          }
          navigationIcon {
            gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
            description
          }
          parentPage {
            ... on ContentfulPage {
              id
              slug
            }
            ... on ContentfulProductPage {
              id
              slug
            }
            ... on ContentfulThemePage {
              id
              slug
            }
          }
        }
      }
    }
    allContentfulProductPage: allContentfulProductPage(
      filter: { node_locale: { eq: $node_locale }, hidden: { ne: true } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      edges {
        node {
          title
          contentful_id
          slug
          updatedAt(locale: "fi-FI")
          node_locale
          contentTopics {
            title
            contentType {
              title
            }
          }
          internal {
            type
          }
          navigationIcon {
            gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
            description
          }
          parentPage {
            ... on ContentfulPage {
              id
              slug
            }
            ... on ContentfulThemePage {
              id
              slug
            }
          }
          hero {
            image {
              gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
              description
            }
          }
        }
      }
    }
    allContentfulThemePage: allContentfulThemePage(
      filter: { node_locale: { eq: $node_locale }, hidden: { ne: true } }
      sort: { fields: updatedAt, order: DESC }
    ) {
      edges {
        node {
          title
          contentful_id
          slug
          updatedAt(locale: "fi-FI")
          node_locale
          contentTopics {
            title
            contentType {
              title
            }
          }
          internal {
            type
          }
          navigationIcon {
            gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
            description
          }
          hero {
            image {
              gatsbyImageData(aspectRatio: 2.0, quality: 65, width: 1000)
              description
            }
          }
        }
      }
    }
  }
`;

export default ContentTopicTemplate;
